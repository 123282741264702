import React,{ useEffect,useState } from 'react';
import { Link, useParams,useNavigate, } from "react-router-dom";
import axios from "axios";
import { setFeedback } from '../redux/store';

import { useSelector, useDispatch } from 'react-redux';
import { LoaderContext } from "../context/Context";
import { useContext } from 'react';
import { useLayoutEffect } from 'react';
import InfosBar from '../components/infosBar';
import Button from '../components/button';
import FormRow from '../components/formRow';
import InputText from '../components/inputText';
import urls from '../constants/urls';

function PartenaireSingleView(props) {
  const { user, token } = useSelector(state => state.persisedReducer.user);

  const dispatch = useDispatch();

  const headers = {
    headers: {
      "x-auth-token": token,
    },
  }
  console.log(user);
  const  {id}  = useParams();
  // console.log(id)
  const loader = useContext(LoaderContext);
  const baseContentUrl = "https://happy-cms.152-228-211-95.plesk.page/wp-json/wp/v2/partenaires"
  // const baseContentUrl = "http://127.0.0.1/vizioCMS/wp-json/wp/v2/faqs"
  const [partner,setPartner] = useState([])
  const [error,setError] = useState(false)
  const [areaerr,setAreaErr] = useState(false)
  const [contact, setContact] = useState(false)
  const [phone, setPhone] = useState (user.phone)
  const [entreprise, setEntreprise] = useState (user.entreprise.name ? user.entreprise.name : "")
  const [mesage, setMessage] = useState ("")
  useLayoutEffect(()=>{
    // loader.onSetIsLoading(true)
    axios.get(`${baseContentUrl}/?slug=${id}`)
    .then((res)=>{
      console.log(res.data);
      setPartner(res.data)
      
      loader.onSetIsLoading(false);
    })
    .catch((err)=>{
      console.log(err);
    })

    return()=>{
      loader.onSetIsLoading(false);
    }
  },[])


  const explode_from_carbon = (input, param, balise)=> {
    if (typeof input !== "string" || typeof param !== "string" || typeof balise !== "string") {
        return;
    }
    if (balise === "") {
        balise = "b";
    }
    let exploded_input = input.split(param);
    let traited_input = "";
    for (let i = 0; i < exploded_input.length; i++) {
        if (i % 2 !== 0) {
            exploded_input[i] = "<" + balise + ">" + exploded_input[i] + "</" + balise + ">";
        }
        traited_input += exploded_input[i];
    }
    return traited_input;
}

const wysiwyg_text = (explode_from_carbon(partner[0]?.wysiwyg_text,"#","br"));

console.log();
  

  const handleContact = ()=>{
    setContact(true)
  }
  const handlePhoneChange = (e)=>{
    if (!(/^\+?[0-9]{9,15}$/.test(e.target.value))){
      setError("Numéro de téléphone non valide")
    }else{
      setError(false)
      setPhone(e.target.value)
    }
    
  }
  const handleEntrepriseChange = (e)=>{
    setEntreprise(e.target.value)
  }
  const handleMessageChange = (e)=>{
    if(e.target.value){
    setMessage(e.target.value)
    setAreaErr(false)
    }
    else{
      setAreaErr(true)
    }
  }

  const handleFormSubmit = (e)=>{
    e.preventDefault()

    let body = {
      partner : partner[0]?.slug,
      partner_lastname : partner[0]?.partner_lastname,
      partner_firstname : partner[0]?.partner_firstname,
      sendEmail:partner[0]?.email_contact,
      phone: phone,
      user_email: user.email,
      societe: entreprise,
      message: mesage
    }
    if (mesage==="" ){
      setAreaErr(true)
      return;
    };
    if (phone==="" ){
      setError(true)
      return;
    };
    axios
    .post(`${urls.apiUrl}/users/contact`, body, headers)
    .then((res)=>{
      // console.log(res.data)
      dispatch(setFeedback({ show: true,  title: `Formulaire de contact`, message: `Formulaire soumis`}));
      // console.log(response);
      setTimeout(() => {
        dispatch(setFeedback({ show: false }));
      }, 2000)
      setContact(false)
    })
    .catch ((err)=>{
      // console.log(err.response.data)
      console.log(err.response.data)
    })
  }

  // console.log(partner);
  return (
    <div className="layout-profile-container">
       {!contact ? 
    <>
      <div className="information-container -medium ">
        <div className="-title">
          <h4 className="-texte">{"Le mot de Vizio"}</h4>
        </div>
        <div className="-information">
          <InfosBar
            classes="-comment"
            content={partner[0]?.partenaire_presentation_description} />
        </div>
      </div>
      <div className="-about">
          <h3 className="-subtitle">{"À PROPOS"}</h3>
          <div className="-aboutcontainer">
            <div className="-img-infos">
              <div className="img_cont">
                <img src={partner[0]?.partenaire_info_image} alt={partner[0]?.partenaire_info_name + " logo"} />
              </div>
              <div className="-infos">
                <span className="-name">{partner[0]?.partenaire_info_name}</span>
                <span className="-service">{partner[0]?.partenaire_info_service}</span>
                <span className="-adress">{partner[0]?.partenaire_info_adresse}</span>
              </div>
            </div>
            <div className="-content">
              <p dangerouslySetInnerHTML={{ __html: wysiwyg_text }} />
            </div>
            <div className='-presta'>
              <span className='label'>{partner[0]?.partenaire_prestation_label}</span>
              <ul className="-prestation">
                {partner[0]?.partenaire_liste_prestation.map((i) => (<li>{i.partenaire_prestation_value}</li>))}
              </ul>
            </div>

          </div>
          <div className="-aboutfooter">
            <div className="-contact">
              <span>{partner[0]?.partenaire_contact_label}</span>
              <span className='-value' dangerouslySetInnerHTML={{ __html: partner[0]?.partenaire_contact_value }} />
            </div>
            <div className="-website">
              <span>{partner[0]?.partenaire_mail_label}</span>
              <Link to={`//${partner[0]?.partenaire_mail_value}`} target="_blank">{partner[0]?.partenaire_mail_value}</Link>
            </div>

            {partner[0]?.have_contact_form && <Button label={`Entrer en contact`} handleClick={() => handleContact()} icon />}

          </div>
      </div>
      <div className="-portfolio">
          <h3 className="-subtitle">{"Portfolio et projets réalisés"}</h3>
          <div className="-slider-cont">
            {/* Afficher le nb de slides dans cet attr "nbr-slides" */}
            <ul className="-sliders" nbr-slides={partner[0]?.partenaire_liste_portfolio.length}>
              {partner[0]?.partenaire_liste_portfolio.map((i) => (
                <li className="-slide">
                  <div className="-img_cont"><img src={i.partenaire_portfolio_image} alt="" /></div>
                  <div className="-nfo">
                    <span>{i.partenaire_portfolio_text}</span>
                    <a href={i.partenaire_portfolio_link_url}>{i.partenaire_portfolio_link_label}</a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
      </div>
      <div className="-services">
          <h3 className="-subtitle">{"Services et prestations"}</h3>

          <div className="-services-container">
            {partner[0]?.partenaire_liste_service.map((i) => (
              <div className="-service">
                <span className="-title">{i.partenaire_service_title}</span>
                <span className="-description" dangerouslySetInnerHTML={{__html: explode_from_carbon(explode_from_carbon(i.partenaire_service_description,"#","br"),"***","b")} }/>
                <span className="-price">{i.partenaire_service_price}</span>
                <span className="-reduction">{i.partenaire_service_reduction}</span>
              </div>
            ))}
          </div>
      </div>
    </>:
    <>
      <div className="-header-title">
      <h4 className="-texte">{`Contacter ${partner[0]?.slug}`}</h4>
      <span>{error}</span>
      </div>
      <form className='-form' onSubmit={(e)=>handleFormSubmit(e)}>
       

        <FormRow classes={"-two"}>
          <InputText
            label= {"Votre numéro de téléphone"}
            value={phone}
            type ={"text"}
            handleInputChange={(e)=>handlePhoneChange(e)}
          />
           <InputText
            label= {"Votre entreprise"}
            value={entreprise}
            required = {false}
            handleInputChange={(e)=>handleEntrepriseChange(e)}
          />
        </FormRow>

        <FormRow classes={"-one"}>
          
          <label className='-lblmsg' for= "test">Votre message</label>
          <textarea className={`-textarea ${areaerr ? "-error" : ""}`} id="test" name="test"
          rows="5" cols="33" onChange={(e)=>handleMessageChange(e)}>
         
          </textarea>
          
        </FormRow>
        <Button
          classes={"-small"}
          label={"Soumettre le message"}
          icon
          type={"submit"}
          />
      </form>
    </>}
    </div>
  );
}

export default PartenaireSingleView;