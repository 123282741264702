import { useDispatch, useSelector } from 'react-redux';
import InputText from '../inputText';
import InputPassword from '../inputPassword';
import InfosBar from '../infosBar';
import FormRow from '../formRow';

import regexes from '../../constants/regex';

import { editField } from '../../redux/store';
import {editRegex} from '../../helpers/global';
import texts from "../../texts/connexion"; 

export default (props) => {
  const {email, password} = useSelector(state => state.notPersisedReducer.registerForm);
  const {lang} = useSelector(state => state.persisedReducer.parameters);
  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    const value = event.target.value;
    let error = false;
    if (value.length === 0 ) error = texts[lang].errorEmpryField;
    else if ( !regexes.email.test(value) ) error = texts[lang].errorMail;
    const payload = {
      name: "email",
      field: {
        value,
        error,
      }
    }
    dispatch(editField(payload));
  }

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    let error = false;
    if (value.length === 0 ) error = texts[lang].errorEmpryField;
    else if ( !editRegex(regexes.password,5,200).test(value)) error = texts[lang].errorPassword;
    const payload = {
      name: "password",
      field: {
        value,
        error,
      }
    }
    dispatch(editField(payload));
  }
  return (
    <div>

      {/* Error Messages */}
      {
        props.error
        && <InfosBar
            content={props.error}
            classes="-error"
          />
      }

      <InfosBar
        content={ texts[lang].information}
        classes="-info"
        style={{
          marginBottom: 40
        }}
      />
      {props.parrain&&<InfosBar
        // content={ `${texts[lang].labelparrainInfo} ${props.parrain.firstname} ${props.parrain.lastname}`}
        content={ `
        ${texts[lang].labelparrainInfo} 
        ${props.parrain.pseudo?props.parrain.pseudo:props.parrain.firstname+ " " + props.parrain.lastname}`}
        classes="-success"
        style={{
          marginBottom: 40
        }}
      />}

      <FormRow classes={"-two"}>
        <InputText
          label={texts[lang].labelMail}
          value={email.value}
          error={email.error}
          handleInputChange={handleEmailChange}
        />
        <InputPassword
          classes="-blue"
          label={texts[lang].labelPassword}
          indication={texts[lang].indicationPassword}
          value={password.value}
          error={password.error}
          handleInputChange={handlePasswordChange}
        />
      </FormRow>

    </div>
  )
}