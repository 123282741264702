// detail real estate
export default {
  fr: {
  presentationText:"Investir sur le long terme avec Vizio Capitalisation",
  btnLabel:"Investir dans Vizio Capitalisation",
  infoHeader:"Informations de Vizio Capitalisation",
  stratLabel:"Stratégie",
  stratText:"Vizio Capitalisation vous propose d'investir dans l'immobilier via des obligations Zéro-coupon. Votre capital sera réinvesti pendant 10 ans pour obtenir une forte rentabilité. A la fin du contrat, vous obtiendrez le capital + les intérêts cumulés pendant les 10 années.",
  docLabel:"Documents à disposition",
  // ContratLink:"Exemple de contrat",
  // societyLink:"",
  infosLink:"Note d’informations",
  bilanLink:"Bilans",

  estimLabel:"Estimation de rentabilité",
  estimTexts:[
    "Notre objectif est de vous proposer un intérêt annuel évolutif grâce à nos différents effets de levier et de vos intérêts qui sont réinvestis dans l’entreprise. De 5 à + de 20% annuel au fur et à mesure de l’évolution de l’entreprise.",
    "A chaque bien vendu, vous percevez un pourcentage; Chaque année, de plus en plus de biens seront vendus, alors vos intérêts vont augmenter.",
  ],
 
  benefLabel:"Récupération des bénéfices",
  benefTexts:[
    "Chaque année, vous pourrez visualiser l’évolution de votre capital sur la plateforme.",
    "Dans 10 ans, vous récupérerez votre capital + les intérêts (déduits du précompte mobilier en fonction de votre situation).",

  ],
  fiscalityLabel:"Fiscalité",
  fiscalityTexts:[
    "La fiscalité est de 30% pour la Belgique.",
    "Si vous êtes résident étranger, nous vous conseillons de contacter un expert fiscaliste et de nous transmettre les informations nécessaires à la mise en place de votre fiscalité personnalisée.",
    "Chaque mois, la société met à disposition de la communauté 1h de discussion avec un expert-fiscaliste en ligne (en développement).",
  ],

  endContratLabel:"Frais d’arrêt de contrat",
  endContratTexts:[
    "Il n’est pas possible d’annuler une obligation.",
    "Dans le cas où vous souhaitez stopper votre investissement,  pour des raisons justifiées à nos avocats, la société peut racheter vos obligations avec des frais de dossier.",

    "année 1 à année 2 : 30% du capital",
    "année 3 à année 4 : 20% du capital",
    "année 5 à année 6 : 10% du capital",

    "Etant donné de votre départ, les intérêts prévus pour l’année de votre départ ne vous seront pas dus.",
    "Vous pourrez faire la demande à :",
    "La société a 12 mois pour vous rembourser.",
  ],
  faqLabel:"Consultez la FAQ",
  rdvLabel:"Prenez rendez-vous"





  },
  en: {
    presentationText:"Investir sur le long terme avec Vizio Capitalisation",
    btnLabel:"Investir dans Vizio Capitalisation",
    infoHeader:"Informations de Vizio Capitalisation",
    stratLabel:"Stratégie",
    stratText:"Vizio Capitalisation vous propose d'investir dans l'immobilier via des obligations Zéro-coupon. Votre capital sera réinvesti pendant 10 ans pour obtenir une forte rentabilité. A la fin du contrat, vous obtiendrez le capital + les intérêts cumulés pendant les 10 années.",
    docLabel:"Documents à disposition",
    // ContratLink:"Exemple de contrat",
    // societyLink:"",
    infosLink:"Note d’informations",
    bilanLink:"Bilans",
  
    estimLabel:"Estimation de rentabilité",
    estimTexts:[
      "Notre objectif est de vous proposer un intérêt annuel évolutif grâce à nos différents effets de levier et de vos intérêts qui sont réinvestis dans l’entreprise. De 5 à + de 20% annuel au fur et à mesure de l’évolution de l’entreprise.",
      "A chaque bien vendu, vous percevez un pourcentage; Chaque année, de plus en plus de biens seront vendus, alors vos intérêts vont augmenter.",
    ],
   
    benefLabel:"Récupération des bénéfices",
    benefTexts:[
      "Chaque année, vous pourrez visualiser l’évolution de votre capital sur la plateforme.",
      "Dans 10 ans, vous récupérerez votre capital + les intérêts (déduits du précompte mobilier en fonction de votre situation).",
  
    ],
    fiscalityLabel:"Fiscalité",
    fiscalityTexts:[
      "La fiscalité est de 30% pour la Belgique.",
      "Si vous êtes résident étranger, nous vous conseillons de contacter un expert fiscaliste et de nous transmettre les informations nécessaires à la mise en place de votre fiscalité personnalisée.",
      "Chaque mois, la société met à disposition de la communauté 1h de discussion avec un expert-fiscaliste en ligne (en développement).",
    ],
  
    endContratLabel:"Frais d’arrêt de contrat",
    endContratTexts:[
      "Il n’est pas possible d’annuler une obligation.",
      "Dans le cas où vous souhaitez stopper votre investissement,  pour des raisons justifiées à nos avocats, la société peut racheter vos obligations avec des frais de dossier.",
  
      "année 1 à année 2 : 30% du capital",
      "année 3 à année 4 : 20% du capital",
      "année 5 à année 6 : 10% du capital",
  
      "Etant donné de votre départ, les intérêts prévus pour l’année de votre départ ne vous seront pas dus.",
      "Vous pourrez faire la demande à :",
      "La société a 12 mois pour vous rembourser.",
    ],
    faqLabel:"Consultez la FAQ",
    rdvLabel:"Prenez rendez-vous"
  }
}