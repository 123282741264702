import InfosBar from '../components/infosBar';
import { useSelector } from "react-redux";
import texts from "../texts/projet-immobilier"

import { LoaderContext } from "../context/Context";
import { useLayoutEffect, useContext, useState } from 'react';
import axios from 'axios';

import Button from '../components/button';
import { Link, Navigate } from 'react-router-dom';



const ProjectImmoView = ()=>{
  const loader = useContext(LoaderContext);
  const {lang} = useSelector(state=>state.persisedReducer.parameters)
  const baseContentUrl = "https://happy-cms.152-228-211-95.plesk.page"
  const path = '/wp-json/wp/v2';
  const [biens,setBiens] = useState([])
  const [taxos, setTaxos] = useState([])
  const [redirect, setRedirect] = useState(false)

  useLayoutEffect(()=>{
    loader.onSetIsLoading(false);
    axios.get(`${baseContentUrl}${path}/bienstaxo`)
    .then((res)=>{
      console.log(res.data);
      setTaxos(res.data)
    })
    axios.get(`${baseContentUrl}${path}/biens`)
    .then((res)=>{
      console.log(res.data);
      setBiens(res.data);
      loader.onSetIsLoading(false);
    })
    return()=>{
      loader.onSetIsLoading(false);
    }
  },[])
  const tab2 = [0,1]

  const tab = [1, 2,3,4]

  console.log(taxos);

  console.log(biens);

  const goToInvest = ()=>{
    setRedirect(true)
  }
  if (redirect) return <Navigate to={"/invest"} replace = {true}/>
return(
  <div className="layout-profile-container">
    <div className="information-container ">
              <div className="-title">
                <h4 className="-texte">{texts[lang].pInfo}</h4>
              </div>
              <div className="-information">
                <InfosBar
                  classes="-info"
                  content={texts[lang].pInfoContent} />
              </div>
      </div>


     {taxos.map((taxo)=>(taxo.count>0 && 
      <div className='-bien-container'>
      <span className="-subtitle">
        <h3>{taxo.name}</h3>
      </span>
        <div className='container-prjcts'> 
              {biens.map((bien)=>(bien.bienstaxo.includes(taxo.id)&&
                <div className='card'>
                <div className="card-body">
                  <span className={bien.bien_immobilier_couleur==="Orange"?'status':'active_status'}>{bien.bien_immobilier_statut}</span>
                    <h2>
                    {bien.title.rendered}
                    </h2>
                    <div className='-bien_image'>
                      <div className="img_cont">
                        <img src={bien.bien_immobilier_image} alt="image du bien" />     {/* //? img vient de base ? admin vizio ?  */}
                      </div>
                      <div className="left-corner">
                        <span className="percent">{bien.bien_immobilier_taux_interet}</span>
                        <span className='-interest'>{bien.bien_immobilier_text_taux_interet}</span>
                        </div>
                    </div>
  
                   <div className='-bien_description'>
                      {/* {//!Possibilité de map sur un sous object du bien en reflexions} */}
                      {bien.bien_caracteristiques.map((opt)=>(
                        <div className='-bien_sub_desc'>
                        <p>
                          {/* {texts[lang].labelDescObjectif} */}
                          {opt.bien_caracteristiques_label}
                          <span>{opt.bien_caracteristiques_value}</span>
                        </p>
                      </div>
                      ))}
                     
                    </div>
  
                   { bien.bien_immobilier_couleur==="Orange"?
                    <div className='-detail'>
                      {/* <Link  to={`//${bien.bien_immobilier_plans}`} target={'_blank'}>{"Télécharger la plaquette du bien"}</Link> */}
                      <a href={bien.bien_immobilier_plans} target="_blank" download>{"Télécharger la plaquette du bien"}</a>
                    </div>:
                   <div className='-bien_footer'>
                      <Button label={`Investir`} handleClick={()=>goToInvest()}    icon />
                    </div>}
                    
                  </div>
                </div>
              )) }       
        </div>
      </div>
     ))}

     
  </div>
)
}
export default ProjectImmoView