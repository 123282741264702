const port = "5000";

export default {
  /* Localhost */
  //  apiUrl: `http://localhost:${port}/immo`,

  /* Shared localhost */
  // apiUrl: `http://192.168.100.123:${port}/immo/`,

  /* Creano server */
  // apiUrl: `http://localhost:${port}/immo/`,

  /* prod */
  // apiUrl: `https://api.viziogroup.com/immo`, // New Api

  /* staging */
  apiUrl: `https://stgapi.viziogroup.com/immo`, // Staging API

  /* Prod */
  // apiUrl: `http://localhost:${port}/immo/`,
};
